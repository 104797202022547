import { rootFontSize } from "./constants";

/**
 * Converts the given value to `rem` unit.
 *
 * @param {number} value - Desired to convert value expressed in pixels
 *
 * @returns {string} - Value converted to `rem`
 */
const pxToRem = (value) => {
  return `${value / rootFontSize}rem`;
};

export default pxToRem;
