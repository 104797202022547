import React, { useEffect, useState } from "react";
import ordersList from "mocks/orders";
import { Grid, useTheme, useMediaQuery, Typography } from "@mui/material";
import DesktopTopMenu from "view/components/headers/desktop-header";
import MobileBottomMenu from "view/components/headers/mobile-header";
import HomeLoading from "../../components/wholesale-loading";
import OrderCard from "view/components/cards/order-card";

export default function Orders() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 0);

  if (loading) {
    return <HomeLoading />;
  }

  return (
    <>
      {isDesktop ? <DesktopTopMenu /> : <MobileBottomMenu />}

      <Grid
        container
        py={3}
        px={{ xs: 1.5, lg: 10 }}
        spacing={isDesktop ? 2 : 1.5}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold">
            HIstórico de pedidos
          </Typography>
        </Grid>
        {ordersList.map((product, index) => (
          <Grid key={index} item xs={6} md={4} lg={3}>
            <OrderCard order={product} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
