import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

function PersonalDataStep({ formik }) {
  return (
    <>
      <Box my={2}>
        <TextField
          fullWidth
          name="name"
          label="Seu nome completo"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Box>

      <Box my={2}>
        <TextField
          fullWidth
          name="email"
          label="Seu e-mail comercial"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Box>

      <Box my={2}>
        <TextField
          fullWidth
          name="password"
          type="password"
          label="Senha"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Box>

      <Box my={2}>
        <TextField
          fullWidth
          name="confirmPassword"
          type="confirmPassword"
          label="Confirmar senha"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
      </Box>
    </>
  );
}

function BusinessDataStep({ formik }) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="user-type-input">Eu sou</InputLabel>
        <Select
          fullWidth
          name="userType"
          label="Eu sou"
          labelId="user-type-input"
          value={formik.values.userType}
          onChange={formik.handleChange}
          error={formik.touched.userType && Boolean(formik.errors.userType)}
          helperText={formik.touched.userType && formik.errors.userType}
        >
          <MenuItem value={20}>Comerciante</MenuItem>
          <MenuItem value={30}>Produtor(a)</MenuItem>
        </Select>
      </FormControl>

      <Box my={2}>
        <TextField
          fullWidth
          name="companyName"
          label="Nome da empresa"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          error={
            formik.touched.companyName && Boolean(formik.errors.companyName)
          }
          helperText={formik.touched.companyName && formik.errors.companyName}
        />
      </Box>

      <Box my={2}>
        <TextField
          fullWidth
          name="cnpj"
          label="CNPJ"
          value={formik.values.cnpj}
          onChange={formik.handleChange}
          error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
          helperText={formik.touched.cnpj && formik.errors.cnpj}
        />
      </Box>

      <Box my={2}>
        <TextField
          fullWidth
          name="companyAddress"
          label="Endereço da empresa"
          value={formik.values.companyAddress}
          onChange={formik.handleChange}
          error={
            formik.touched.companyAddress &&
            Boolean(formik.errors.companyAddress)
          }
          helperText={
            formik.touched.companyAddress && formik.errors.companyAddress
          }
        />
      </Box>
    </>
  );
}

function renderFormSteps(activeStep, formik) {
  switch (activeStep) {
    case 0:
      return <PersonalDataStep formik={formik} />;
    case 1:
      return <BusinessDataStep formik={formik} />;
    default:
      return "Não encontrado";
  }
}

export default renderFormSteps;
