const supportMessages = [
  {
    title: "Lorem ipsum dolor sit amet?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae finibus tortor. Nam tincidunt scelerisque sapien quis scelerisque. Mauris molestie nec risus nec vulputate.",
  },
  {
    title: "Lorem ipsum dolor sit amet?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae finibus tortor. Nam tincidunt scelerisque sapien quis scelerisque. Mauris molestie nec risus nec vulputate.",
  },
  {
    title: "Lorem ipsum dolor sit amet?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae finibus tortor. Nam tincidunt scelerisque sapien quis scelerisque. Mauris molestie nec risus nec vulputate.",
  },
  {
    title: "Lorem ipsum dolor sit amet?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae finibus tortor. Nam tincidunt scelerisque sapien quis scelerisque. Mauris molestie nec risus nec vulputate.",
  },
  {
    title: "Lorem ipsum dolor sit amet?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae finibus tortor. Nam tincidunt scelerisque sapien quis scelerisque. Mauris molestie nec risus nec vulputate.",
  },
  {
    title: "Lorem ipsum dolor sit amet?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae finibus tortor. Nam tincidunt scelerisque sapien quis scelerisque. Mauris molestie nec risus nec vulputate.",
  },
];

export default supportMessages;
