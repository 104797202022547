import pxToRem from "../utils";

const MuiContainer = {
  root: {
    paddingLeft: pxToRem(28),
    paddingRight: pxToRem(28),
  },
};

export default MuiContainer;
