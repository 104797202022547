import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// import useAuth from "operations/auth";

import { pages } from "../../routes";
import loginBanner from "view/images/login.svg";
import googleIcon from "view/images/google-icon.svg";

import loginTexts from "./constants";

export default function Login() {
  // const { federatedSignIn } = useAuth();

  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const validationSchema = Yup.object({
    email: Yup.string("Digite o seu e-mail")
      .email("Esse e-mail não é válido")
      .required("E-mail é obrigatório"),
    password: Yup.string("Digite a sua senha")
      .min(5, "A senha deve ter no minimo 5 caracteres")
      .required("Senha é obrigatória"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (!formik.errors.length) {
        navigate(pages.wholesale);
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ height: isDesktop ? "100vh" : "250px" }}>
        <Box
          bgcolor="#FAF4E8"
          width="100%"
          height="100%"
          sx={{
            backgroundImage: `url(${loginBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: isDesktop ? "top center" : "top right -10px",
            backgroundSize: "cover",
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ height: "100vh" }}>
        <form onSubmit={formik.handleSubmit} style={{ height: "100vh" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="white"
            height="100%"
          >
            <Box maxWidth={isDesktop ? "45%" : "90%"}>
              <Typography variant="h3" fontWeight="bold">
                {loginTexts.welcome}
              </Typography>
              <Typography>{loginTexts.emailAndPassword}</Typography>
              <Box my={2}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box my={2}>
                <TextField
                  fullWidth
                  label="Senha"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="outlined"
                />
              </Box>
              <Box my={2} textAlign="right">
                <Link to={pages.login} style={{ textDecoration: "none" }}>
                  {loginTexts.forgotPassword}
                </Link>
              </Box>
              <Box my={2}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disableElevation
                  type="submit"
                >
                  {loginTexts.enterButton}
                </Button>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                my={2}
              >
                <Divider sx={{ width: "41%" }} />
                <Typography sx={{ margin: "0 10px" }}>ou</Typography>
                <Divider sx={{ width: "41%" }} />
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  disableElevation
                  // onClick={federatedSignIn(providers.google)}
                >
                  <Box component="img" src={googleIcon} pr={1} />
                  {loginTexts.enterWithGoogleButton}
                </Button>
              </Box>
              <Box mt={8} mb={3} textAlign="center">
                <Typography>{loginTexts.dontHaveAccount}</Typography>
                <Link
                  to={pages.createAccount}
                  style={{ textDecoration: "none" }}
                >
                  {loginTexts.createAccount}
                </Link>
              </Box>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
}
