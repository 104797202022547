import React, { useState } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import "./style.css";

export default function ProductFilters({ list, filterList }) {
  const [canShowDeleteBtn, setcanShowDeleteBtn] = useState({
    lessPrice: false,
    higherPrice: false,
    alphabeticAtoZ: false,
    alphabeticZtoA: false,
  });

  const addLessPriceFilter = () => {
    setcanShowDeleteBtn({ ...canShowDeleteBtn, lessPrice: true });

    const filteredList = list.sort((a, b) => {
      if (a.price > b.price) {
        return 1;
      }
      if (a.price < b.price) {
        return -1;
      }
      return 0;
    });

    filterList(filteredList);
  };

  const addHigherPriceFilter = () => {
    setcanShowDeleteBtn({ ...canShowDeleteBtn, higherPrice: true });

    const filteredList = list.sort((a, b) => {
      if (a.price > b.price) {
        return -1;
      }
      if (a.price < b.price) {
        return 1;
      }
      return 0;
    });

    filterList(filteredList);
  };

  const addAZFilter = () => {
    setcanShowDeleteBtn({ ...canShowDeleteBtn, alphabeticAtoZ: true });

    const filteredList = list.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    filterList(filteredList);
  };

  const addZAFilter = () => {
    setcanShowDeleteBtn({ ...canShowDeleteBtn, alphabeticZtoA: true });

    const filteredList = list.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      }
      if (a.name < b.name) {
        return 1;
      }
      return 0;
    });

    filterList(filteredList);
  };

  return (
    <Grid container my={2}>
      <Grid item xs={12} md={1.3} lg={1} mb={1} pr={1}>
        <Typography fontWeight="bold">Ordenar por:</Typography>
      </Grid>

      <Grid
        item
        xs={12}
        md={10.7}
        lg={11}
        display="flex"
        alignItems="center"
        className="override-horizontal-scrollbar"
        style={{
          overflowX: "scroll",
          whiteSpace: "nowrap",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          width: "auto",
        }}
      >
        <Box mx={1}>
          <Chip
            label="Menor preço"
            onClick={addLessPriceFilter}
            variant={canShowDeleteBtn.lessPrice ? null : "outlined"}
            onDelete={
              canShowDeleteBtn.lessPrice
                ? () =>
                    setcanShowDeleteBtn({
                      ...canShowDeleteBtn,
                      lessPrice: false,
                    })
                : undefined
            }
          />
        </Box>
        <Box mx={1}>
          <Chip
            label="Maior preço"
            onClick={addHigherPriceFilter}
            variant={canShowDeleteBtn.higherPrice ? null : "outlined"}
            onDelete={
              canShowDeleteBtn.higherPrice
                ? () =>
                    setcanShowDeleteBtn({
                      ...canShowDeleteBtn,
                      higherPrice: false,
                    })
                : undefined
            }
          />
        </Box>
        {/* <Box mx={1}>
          <Chip label="Tempo: menor" onClick={() => {}} variant="outlined" />
        </Box>
        <Box mx={1}>
          <Chip label="Tempo: maior" onClick={() => {}} onDelete={() => {}} />
        </Box> */}
        <Box mx={1}>
          <Chip
            label="Alfabética: A-Z"
            onClick={addAZFilter}
            variant={canShowDeleteBtn.alphabeticAtoZ ? null : "outlined"}
            onDelete={
              canShowDeleteBtn.alphabeticAtoZ
                ? () =>
                    setcanShowDeleteBtn({
                      ...canShowDeleteBtn,
                      alphabeticAtoZ: false,
                    })
                : undefined
            }
          />
        </Box>
        <Box mx={1}>
          <Chip
            label="Alfabética: Z-A"
            onClick={addZAFilter}
            variant={canShowDeleteBtn.alphabeticZtoA ? null : "outlined"}
            onDelete={
              canShowDeleteBtn.alphabeticZtoA
                ? () =>
                    setcanShowDeleteBtn({
                      ...canShowDeleteBtn,
                      alphabeticZtoA: false,
                    })
                : undefined
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}
