import { Route, Routes, Navigate } from "react-router-dom";
import Wholesale from "./pages/wholesale";
import Login from "./pages/login";
import Support from "./pages/support";
import CreateAccount from "./pages/create-account";
import Orders from "./pages/orders";
import Payment from "./pages/payment";
import MyAccount from "./pages/my-account";
import ProductDetails from "./pages/product-details";

export const pages = {
  login: "/",
  createAccount: "/criar-conta",
  wholesale: "/atacado",
  productDetails: "/atacado/produto/:productId",
  support: "/suporte",
  myAccount: "/meus-dados",
  orders: "/meus-pedidos",
  payment: "/pagamento",
};

function AppRoutes() {
  return (
    <Routes>
      <Route exact path={pages.login} element={<Login />} />
      <Route exact path={pages.createAccount} element={<CreateAccount />} />
      <Route exact path={pages.wholesale} element={<Wholesale />} />
      <Route exact path={pages.productDetails} element={<ProductDetails />} />
      <Route path={pages.support} element={<Support />} />
      <Route path={pages.orders} element={<Orders />} />
      <Route path={pages.payment} element={<Payment />} />
      <Route path={pages.myAccount} element={<MyAccount />} />
      <Route path="*" element={<Navigate to={pages.home} replace />} />
    </Routes>
  );
}

export default AppRoutes;
