export const cartInitialState = {
  cartItems: [],
};

export const getCartTotalItemsLength = (state) => state?.cartItems.length;

export const getCartTotalValue = (state) => {
  if (state.cartItems.length > 0) {
    const total = state.cartItems.reduce(
      (actualCartValue, newProduct) => newProduct.price + actualCartValue,
      0
    );

    return total.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return {
        cartItems: [...state.cartItems, action.product],
      };
    default:
      return state;
  }
};

export default cartReducer;
