import React from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCartItems } from "contexts/cart-context";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CartItem from "view/components/cards/cart-item";
import { colors } from "mui-overrides/principles";
import { getCartTotalValue } from "contexts/cart-context/reducer";
import { pages } from "view/routes";
import { useNavigate } from "react-router";

export default function CartDrawer({ isOpen, anchor, onClose }) {
  const navigate = useNavigate();
  const [state] = useCartItems();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const goToPayment = () => navigate(pages.payment);

  const cartTotal = getCartTotalValue(state);
  const cartItemsTotalPrice = cartTotal ? `${cartTotal}` : "R$ 0";

  const cartItemsLength =
    state.cartItems.length === 1
      ? `${state.cartItems.length} item`
      : `${state.cartItems.length} items`;

  return (
    <React.Fragment>
      <Drawer
        anchor={anchor}
        open={isOpen}
        ModalProps={{ onBackdropClick: onClose }}
        PaperProps={{
          sx: {
            height: "100%",
            padding: 2,
            borderTop: 1,
          },
        }}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton onClick={onClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box width="100%">
            <Typography textAlign="center" fontWeight="bold">
              Seu carrinho
            </Typography>
          </Box>
        </Box>

        <Box
          width={anchor === "top" || anchor === "bottom" ? "auto" : 400}
          height="100%"
          style={{ overflowX: "hidden" }}
        >
          {state.cartItems.length > 0 && (
            <Grid container py={3} spacing={1}>
              {state.cartItems.map((product, index) => (
                <Grid item xs={12}>
                  <CartItem product={product} key={index} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <Box width="100%" pt={3} pb={2} bgcolor={colors.root[0]}>
          <Box
            display="flex"
            justifyContent="space-between"
            width={isDesktop ? "400px" : "100%"}
          >
            <Box>
              <Typography fontWeight="bold" textAlign="left">
                {cartItemsTotalPrice}
              </Typography>
              <Typography textAlign="left">{cartItemsLength}</Typography>
            </Box>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={goToPayment}
            >
              Ir para pagamento
            </Button>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
