import React, { useState } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Box,
  Typography,
  Slide,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router";
import { colors } from "mui-overrides/principles";
import { pages } from "view/routes";
import CartDrawer from "../drawers/cart";
import { getCartTotalValue } from "contexts/cart-context/reducer";
import { useCartItems } from "contexts/cart-context";

export default function MobileBottomMenu() {
  const [state] = useCartItems();

  const navigate = useNavigate();
  const [canShowCart, setCanShowCart] = useState(false);

  const handleOpenCartClick = () => setCanShowCart(true);
  const handleCloseCartClick = () => setCanShowCart(false);

  const handleSupportClick = () => navigate(pages.support);
  const handleWholesaleClick = () => navigate(pages.wholesale);

  const cartTotal = getCartTotalValue(state);
  const cartItemsTotalPrice = cartTotal ? `${cartTotal}` : "R$ 0";

  const isCartNotEMpty = state.cartItems.length >= 1;

  const cartItemsLength =
    state.cartItems.length === 1
      ? `${state.cartItems.length} item`
      : `${state.cartItems.length} items`;

  return (
    <>
      <CartDrawer
        isOpen={canShowCart}
        anchor="bottom"
        onClose={handleCloseCartClick}
      />

      {isCartNotEMpty && (
        <Slide direction="up" in={isCartNotEMpty} mountOnEnter unmountOnExit>
          <Box
            width="100%"
            position="fixed"
            bgcolor={colors.orange[50]}
            sx={{ top: "auto", bottom: 56 }}
            zIndex={2}
            onClick={handleOpenCartClick}
          >
            <Toolbar>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <ShoppingBagOutlinedIcon style={{ color: colors.root[0] }} />
                  <Box mx={1}>
                    <Typography
                      fontWeight="bold"
                      color={colors.root[0]}
                      fontSize={15}
                    >
                      Ver meu pedido:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color={colors.root[0]} fontSize={14}>
                      {cartItemsLength}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    fontWeight="bold"
                    color={colors.root[0]}
                    fontSize={14}
                  >
                    {cartItemsTotalPrice}
                  </Typography>
                </Box>
              </Box>
            </Toolbar>
          </Box>
        </Slide>
      )}

      <AppBar color="common" position="fixed" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-around" }}>
          <IconButton
            color="inherit"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <AddShoppingCartIcon />
            <Box component="span" fontSize={14} onClick={handleWholesaleClick}>
              Atacado
            </Box>
          </IconButton>
          <IconButton
            color="inherit"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <SearchIcon />
            <Box component="span" fontSize={14}>
              Buscar
            </Box>
          </IconButton>
          <IconButton
            color="inherit"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <SupportAgentIcon />
            <Box component="span" fontSize={14} onClick={handleSupportClick}>
              Suporte
            </Box>
          </IconButton>
          <IconButton
            color="inherit"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <PersonOutlineIcon />
            <Box component="span" fontSize={14}>
              Perfil
            </Box>
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
