import React from "react";
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  CardActionArea,
} from "@mui/material";
import organicSeal from "view/images/organicos.svg";
import { useCartItems } from "contexts/cart-context";

export default function ProductCard({ product, onClick }) {
  //eslint-disable-next-line
  const [state, dispatch] = useCartItems();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {
    name: productName,
    image: productImage,
    price: productPrice,
  } = product;

  const acessibility = {
    ariaLabel: `O preço é de R$ ${productPrice}`,
    productImgAlt: `Imagem de um(a) ${productName}`,
    organicSealImgAlt: `Imagem do selo de produto certificadamente orgânico`,
  };

  const addProductToCart = () => {
    dispatch({
      type: "add",
      product,
    });
  };

  return (
    <Card
      variant="outlined"
      style={{ padding: isDesktop ? "15px" : "5px", borderRadius: "12px" }}
    >
      <Grid container>
        <CardActionArea onClick={onClick}>
          <Grid item xs={12} sm={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor="#FFFAF4"
              minHeight={isDesktop ? "180px" : "100px"}
            >
              <CardMedia
                component="img"
                image={productImage}
                alt={acessibility.productImgAlt}
                style={{
                  width: "100%",
                  maxWidth: isDesktop ? "150px" : "100px",
                  maxHeight: isDesktop ? "150px" : "100px",
                  height: "auto",
                  padding: "10px",
                }}
              />
              <CardMedia
                component="img"
                image={organicSeal}
                alt={acessibility.organicSealImgAlt}
                style={{
                  width: "auto",
                  padding: "0px 10px 10px",
                  marginLeft: "auto",
                }}
              />
            </Box>
            <CardContent style={{ padding: "0px" }}>
              <Box mt={1}>
                <Typography textAlign="center">{productName}</Typography>
                <Typography
                  fontWeight="bold"
                  textAlign="center"
                  variant={isDesktop ? "subtitle1" : "subtitle2"}
                  aria-label={acessibility.ariaLabel}
                >
                  R$ {productPrice}
                </Typography>
              </Box>
            </CardContent>
          </Grid>
        </CardActionArea>

        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          pl={isDesktop ? 0 : 1}
        >
          <CardActions
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              disableElevation
              variant="contained"
              onClick={addProductToCart}
            >
              <Typography component="span" fontWeight="bold" variant="body1">
                Adicionar
              </Typography>
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
}
