import React, { useState } from "react";
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  Grid,
  ButtonGroup,
  InputAdornment,
  FormControl,
  OutlinedInput,
} from "@mui/material";

export default function CartItem({ product }) {
  const [cartItemQuantity, setCartItemQuantity] = useState(0);
  const { name, image, price } = product;
  const ariaLabelValue = `O preço é de R$ ${price}`;

  const incrementItemQuantity = () => setCartItemQuantity(cartItemQuantity + 1);
  const decrementItemQuantity = () => {
    if (cartItemQuantity === 0) return;

    setCartItemQuantity(cartItemQuantity - 1);
  };

  return (
    <Card variant="outlined" style={{ padding: "5px", borderRadius: "12px" }}>
      <Grid container>
        <Grid item xs={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#FFFAF4"
            minHeight="80px"
          >
            <CardMedia
              component="img"
              image={image}
              alt="Paella dish"
              style={{
                width: "100%",
                maxWidth: "80px",
                maxHeight: "80px",
                height: "auto",
                padding: "10px",
                margin: "auto",
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={9}>
          <CardContent style={{ padding: "0px 0px 0px 10px" }}>
            <Box mt={1}>
              <Typography variant="subtitle2">{name}</Typography>
            </Box>
          </CardContent>

          <CardActions>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="bold"
                variant="subtitle2"
                aria-label={ariaLabelValue}
              >
                R$ {price}
              </Typography>

              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                style={{ boxShadow: "none" }}
              >
                <Button
                  variant="small"
                  style={{ borderRight: "none", fontWeight: "bold" }}
                  onClick={decrementItemQuantity}
                >
                  -
                </Button>
                <FormControl sx={{ width: "100px" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={cartItemQuantity}
                    endAdornment={
                      <InputAdornment position="end">kg</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    style={{ height: "2em" }}
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
                <Button
                  variant="small"
                  style={{ fontWeight: "bold" }}
                  onClick={incrementItemQuantity}
                >
                  +
                </Button>
              </ButtonGroup>
            </Box>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
}
