import { createTheme } from "@mui/material";
import * as overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";
import "typeface-open-sans";

const theme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: 20,
  },
  overrides: {
    ...overrides,
  },
  props: {
    MuiRadio: {
      color: "primary",
    },
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
});

export default theme;
