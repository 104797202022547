import bananaImg from "view/images/products/banana.png";
import alfaceCrespaImg from "view/images/products/alface-crespa.png";
import tomateImg from "view/images/products/tomate.png";
import batataImg from "view/images/products/batata.png";

const productsList = [
  {
    id: 1,
    name: "Banana Prata",
    image: bananaImg,
    price: 8.2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam.",
  },
  {
    id: 2,
    name: "Alface crespa",
    image: alfaceCrespaImg,
    price: 6.5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam.",
  },
  {
    id: 3,
    name: "Tomate carmem",
    image: tomateImg,
    price: 7.5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam.",
  },
  {
    id: 4,
    name: "Batata da terra",
    image: batataImg,
    price: 6.0,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat vulputate eros, sit amet rutrum nibh placerat a. Sed et nisi urna. Cras sed libero laoreet, pretium dui in, finibus quam.",
  },
];

export default productsList;
