import React from "react";
import {
  Card,
  Box,
  Skeleton,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";

export default function HomeLoading() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={300} />
        </Grid>
      </Grid>

      <Grid container pt={4} pb={3}>
        <Grid item xs={6} display="flex" justifyContent="end" pr={1}>
          <Skeleton
            variant={isDesktop ? "rectangular" : "circular"}
            width={isDesktop ? "80%" : 100}
            height={isDesktop ? 150 : 100}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="start" pl={3}>
          <Skeleton
            variant={isDesktop ? "rectangular" : "circular"}
            width={isDesktop ? "80%" : 100}
            height={isDesktop ? 150 : 100}
          />
        </Grid>
      </Grid>

      <Grid container px={{ xs: 1.5, lg: 10 }}>
        <Grid item xs={12} display="flex" justifyContent="space-around">
          <Skeleton variant="text" width="100%" height={50} />
        </Grid>
      </Grid>

      <Grid
        container
        py={3}
        px={{ xs: 1.5, lg: 10 }}
        spacing={isDesktop ? 4 : 1.5}
      >
        {Array.from(Array(8)).map((_, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Card
              variant="outlined"
              style={{ padding: "15px", borderRadius: "12px" }}
            >
              <Grid container>
                <Grid item xs={4} sm={12}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={isDesktop ? 200 : 100}
                  />
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={12}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  pl={isDesktop ? 0 : 1}
                >
                  <Box py={1}>
                    <Skeleton variant="text" width="100%" height={35} />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Skeleton variant="rectangular" width="43%" height={40} />
                    <Skeleton variant="rectangular" width="43%" height={40} />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
