import * as React from "react";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputBase } from "@mui/material";
import { colors } from "mui-overrides/principles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: colors.smoke[100],
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "auto",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 6),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    width: "20ch",
    [theme.breakpoints.up("lg")]: {
      width: "50ch",
    },
  },
}));

export default function SearchInput() {
  return (
    <Search sx={{ marginRight: 11 }}>
      <StyledInputBase
        placeholder="Procurar produtos"
        inputProps={{ "aria-label": "Procurar produtos" }}
      />
      <IconButton sx={{ margin: "5px 15px", padding: "14px" }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </IconButton>
    </Search>
  );
}
