const loginTexts = {
  welcome: "Bem-vindo(a)",
  emailAndPassword: "Digite seu e-mail e senha para entrar na plataforma.",
  forgotPassword: "Esqueci minha senha",
  dontHaveAccount: "Não possui uma conta? ",
  createAccount: "Clique aqui e crie.",
  enterButton: "Entrar",
  enterWithGoogleButton: "Entrar com o Google",
};

export default loginTexts;
