import React, { useState } from "react";
import {
  AppBar,
  MenuItem,
  Box,
  Toolbar,
  Button,
  Container,
  Menu,
  Typography,
  ListItemIcon,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { colors } from "mui-overrides/principles";
import logo from "view/images/logo.png";
import SearchInput from "../custom-inputs/search";
import CartDrawer from "../drawers/cart";
import { useNavigate } from "react-router";
import { pages } from "view/routes";
import headerTexts from "./constants";
import { useCartItems } from "contexts/cart-context";
import { getCartTotalValue } from "contexts/cart-context/reducer";

function DesktopTopMenu() {
  const [state] = useCartItems();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [canShowCart, setCanShowCart] = useState(false);

  const handleOpenCartClick = () => setCanShowCart(true);
  const handleCloseCartClick = (event) => setCanShowCart(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSupportClick = () => navigate(pages.support);
  const handleWholesaleClick = () => navigate(pages.wholesale);

  const settings = [
    { icon: <ReceiptLongIcon />, key: "Pedidos", path: pages.orders },
    { icon: <PermIdentityIcon />, key: "Meus dados", path: pages.myAccount },
    { icon: <LogoutIcon />, key: "Sair", path: pages.orders },
  ];

  const cartTotal = getCartTotalValue(state);
  const cartItemsTotalPrice = cartTotal ? `${cartTotal}` : "R$ 0";

  const cartItemsLength =
    state.cartItems.length === 1
      ? `${state.cartItems.length} item`
      : `${state.cartItems.length} items`;

  return (
    <>
      <CartDrawer
        isOpen={canShowCart}
        anchor="right"
        onClose={handleCloseCartClick}
      />

      <AppBar
        position="sticky"
        color="common"
        sx={{
          boxShadow: "none",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Box component="img" src={logo} maxWidth="100px" />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
              <SearchInput />
              <Box mx={1}>
                <Button
                  onClick={handleWholesaleClick}
                  sx={{ my: 2, display: "block" }}
                >
                  {headerTexts.wholesale}
                </Button>
              </Box>

              <Box mx={1}>
                <Button
                  sx={{ my: 2, display: "block" }}
                  onClick={handleSupportClick}
                >
                  {headerTexts.support}
                </Button>
              </Box>

              <Box mx={1}>
                <Button
                  onClick={handleOpenUserMenu}
                  sx={{ my: 2, display: "block" }}
                >
                  {headerTexts.profile}
                </Button>
              </Box>

              <Button mx={3} display="flex" onClick={handleOpenCartClick}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor={colors.orange[50]}
                  width="40px"
                  height="40px"
                  borderRadius={20}
                  mr={1}
                >
                  <ShoppingBagOutlinedIcon
                    style={{ color: "white", width: "25px", height: "25px" }}
                  />
                </Box>
                <Box>
                  <Typography fontWeight="bold" textAlign="left">
                    {cartItemsTotalPrice}
                  </Typography>
                  <Typography textAlign="left">{cartItemsLength}</Typography>
                </Box>
              </Button>

              <Box sx={{ flexGrow: 0 }}>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => navigate(setting.path)}
                      style={{ padding: "15px 60px 15px 20px" }}
                    >
                      <ListItemIcon>{setting.icon}</ListItemIcon>
                      <Typography textAlign="center">{setting.key}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default DesktopTopMenu;
