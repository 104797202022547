import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Stepper,
  Stack,
  StepLabel,
  Step,
} from "@mui/material";
import { pages } from "../../routes";
import loginBanner from "view/images/login.svg";
import createAccountTexts from "./constants";
import renderFormSteps from "./components/form-steps";
import { useFormik } from "formik";

export default function CreateAccount() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [activeStep, setActiveStep] = useState(0);

  const handleFormSubmit = () => {
    if (isLastStep) {
      navigate(pages.wholesale);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const formSteps = ["Dados pessoais", "Sua empresa"];
  const isLastStep = activeStep === formSteps.length - 1;

  const validationSchema = [
    Yup.object({
      email: Yup.string("Digite o seu e-mail comercial")
        .email("Esse e-mail não é válido")
        .required("E-mail comercial é obrigatório"),
      name: Yup.string().required("Digite seu nome completo"),
      password: Yup.string("Digite a sua senha")
        .min(5, "A senha deve ter no minimo 5 caracteres")
        .required("Senha é obrigatória"),
      confirmPassword: Yup.string("Digite a sua senha")
        .min(5, "A senha deve ter no minimo 5 caracteres")
        .required("Senha é obrigatória"),
    }),
    Yup.object({
      userType: Yup.string()
        .nullable()
        .required("Please indicate your communications preference"),
      companyName: Yup.string("Digite o nome da empresa").required(
        "Nome da empresa é obrigatório"
      ),
      cnpj: Yup.string("Digite o CNPJ da empresa").required(
        "CNPJ da empresa é obrigatório"
      ),
      companyAddress: Yup.string("Digite o endereço da empresa").required(
        "Endereço da empresa é obrigatório"
      ),
    }),
  ];

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      userType: "",
      companyName: "",
      cnpj: "",
      companyAddress: "",
    },
    validationSchema: validationSchema[activeStep],
    onSubmit: () => {
      handleFormSubmit();
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ height: isDesktop ? "100vh" : "250px" }}>
        <Box
          bgcolor="#FAF4E8"
          width="100%"
          height="100%"
          sx={{
            backgroundImage: `url(${loginBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: isDesktop ? "top center" : "top right -10px",
            backgroundSize: "cover",
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ height: "100vh" }}>
        <form onSubmit={formik.handleSubmit} style={{ height: "100vh" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="white"
            height="100%"
          >
            <Box maxWidth={isDesktop ? "45%" : "90%"}>
              <Typography variant="h3" fontWeight="bold">
                Cadastro
              </Typography>
              <Typography>
                Preencha os dados para completar o seu cadastro.
              </Typography>
              <Box my={2}>
                <Stack sx={{ width: "100%" }} spacing={4}>
                  <Stepper alternativeLabel activeStep={activeStep}>
                    {formSteps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              </Box>

              {renderFormSteps(activeStep, formik)}

              <Box my={2}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disableElevation
                  type="submit"
                >
                  CONTINUAR
                </Button>
              </Box>

              <Box mt={8} mb={3} textAlign="center">
                <Typography>{createAccountTexts.alreadyHaveAccount}</Typography>
                <Link to={pages.login} style={{ textDecoration: "none" }}>
                  {createAccountTexts.enterNowButton}
                </Link>
              </Box>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
}
