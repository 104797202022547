import React from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DesktopTopMenu from "view/components/headers/desktop-header";
import { colors } from "mui-overrides/principles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MobileBottomMenu from "view/components/headers/mobile-header";
import supportMessages from "./constants";

export default function Support() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {isDesktop ? <DesktopTopMenu /> : <MobileBottomMenu />}

      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="300px"
          bgcolor={colors.orange[50]}
          mb={5}
        >
          <Typography
            fontWeight="bold"
            fontSize={isDesktop ? 45 : 30}
            textAlign="center"
            color="white"
          >
            Dúvidas?
          </Typography>
        </Box>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={11} md={7}>
            {supportMessages.map((message, index) => (
              <Box mb={3}>
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{message.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{message.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
