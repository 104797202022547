import React, { createContext, useCallback, useContext, useMemo } from "react";
import Auth from "@aws-amplify/auth";

export const AuthProviderContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const federatedSignIn = useCallback(async (provider) => {
    try {
      await Auth.federatedSignIn({ provider });
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const memoizedValues = useMemo(
    () => ({
      federatedSignIn,
    }),
    [federatedSignIn]
  );

  return (
    <AuthProviderContext.Provider value={memoizedValues}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export const errorMessage =
  "`useAuth` hook must be used within an `AuthProvider` component";

const useAuth = () => {
  const context = useContext(AuthProviderContext);

  if (!context) {
    throw new Error(errorMessage);
  }

  return context;
};

export default useAuth;
