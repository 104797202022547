import React from "react";
import { Box, Button } from "@mui/material";

export default function Payment() {
  return (
    <Box>
      <label>
        Card details
        <Button disableElevation variant="contained">
          Confirmar pagamento
        </Button>
      </label>
    </Box>
  );
}
