import React, { createContext, useContext, useReducer } from "react";
import cartReducer, { cartInitialState } from "contexts/cart-context/reducer";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, cartInitialState);

  return (
    <CartContext.Provider value={[state, dispatch]}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartItems = () => useContext(CartContext);

export default CartProvider;
