import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import organicSeal from "view/images/organicos.svg";
import DesktopTopMenu from "view/components/headers/desktop-header";
import MobileBottomMenu from "view/components/headers/mobile-header";
import { useLocation } from "react-router";

export default function ProductDetails() {
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {
    name: productName,
    image: productImage,
    price: productPrice,
    description: productDescription,
  } = location.state;

  const acessibility = {
    ariaLabel: `O preço é de R$ ${productPrice}`,
    productImgAlt: `Imagem de um(a) ${productName}`,
    organicSealImgAlt: `Imagem do selo de produto certificadamente orgânico`,
  };

  return (
    <>
      {isDesktop ? <DesktopTopMenu /> : <MobileBottomMenu />}
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor="#FFFAF4"
              minHeight={isDesktop ? "350px" : "100px"}
            >
              <Box
                component="img"
                src={productImage}
                alt={acessibility.productImgAlt}
                style={{
                  width: "100%",
                  maxWidth: isDesktop ? "250px" : "100px",
                  maxHeight: isDesktop ? "250px" : "100px",
                  height: "auto",
                  padding: "10px",
                }}
              />
              <Box
                component="img"
                src={organicSeal}
                alt={acessibility.organicSealImgAlt}
                style={{
                  width: "auto",
                  padding: "0px 10px 10px",
                  marginLeft: "auto",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box mt={1}>
              <Typography variant="h6" fontWeight="bold">
                {productName}
              </Typography>
              <Typography>{productDescription}</Typography>
            </Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="bold">
                {productPrice}
              </Typography>
              <Button disableElevation variant="contained">
                <Typography component="span" fontWeight="bold" variant="body1">
                  Adicionar
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
