import React, { useEffect, useState } from "react";
import ProductCard from "../../components/cards/product-card";
// import ProductCardWithCounter from "view/components/cards/product-card-wholesale";
import productsList from "mocks/products";
import banner from "view/images/banner.svg";
import volume from "view/images/volume.png";
import imperfect from "view/images/imperfeitos.png";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import DesktopTopMenu from "view/components/headers/desktop-header";
import MobileBottomMenu from "view/components/headers/mobile-header";
import HomeLoading from "../../components/wholesale-loading";
import ProductFilters from "./components/Filters";
import { useNavigate } from "react-router";
import { pages } from "view/routes";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Wholesale() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState(productsList);

  useEffect(() => {
    setLoading(true);
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const filterList = (filteredList) => {
    setList([...filteredList]);
  };

  const goToProductDetails = ({ product }) => {
    return navigate(pages.productDetails.replace(":productId", product.id), {
      state: product,
    });
  };

  var sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: true,
    // lazyLoad: "ondemand",
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  if (loading) {
    return <HomeLoading />;
  }

  return (
    <>
      {isDesktop ? <DesktopTopMenu /> : <MobileBottomMenu />}

      <Grid container>
        <Grid item xs={12}>
          <Slider {...sliderSettings}>
            <Box component="img" width="100%" src={banner} mb={3} />
            <Box component="img" width="100%" src={banner} mb={3} />
          </Slider>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} display="flex" justifyContent="end" pr={2}>
          <Box
            component="img"
            width="100%"
            src={volume}
            mb={3}
            maxWidth="400px"
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="start" pl={2}>
          <Box
            component="img"
            width="100%"
            src={imperfect}
            mb={3}
            maxWidth="400px"
          />
        </Grid>
      </Grid>

      <Box container px={{ xs: 1.5, lg: 10 }}>
        <ProductFilters list={list} filterList={filterList} />
      </Box>

      <Grid
        container
        py={3}
        px={{ xs: 1.5, lg: 10 }}
        spacing={isDesktop ? 2 : 1.5}
      >
        {list.map((product, index) => (
          <Grid key={index} item xs={6} md={4} lg={2}>
            <ProductCard
              product={product}
              onClick={() => goToProductDetails({ product })}
            />
          </Grid>
        ))}
      </Grid>

      {/* <Grid
        container
        py={3}
        px={{ xs: 1.5, lg: 10 }}
        spacing={isDesktop ? 2 : 1.5}
      >
        {list.map((product, index) => (
          <Grid key={index} item xs={6} md={4} lg={2}>
            <ProductCardWithCounter product={product} />
          </Grid>
        ))}
      </Grid> */}
    </>
  );
}

// const [activeStep, setActiveStep] = React.useState(0);

// const handleNext = () => {
//   setActiveStep((prevActiveStep) => prevActiveStep + 1);
// };

// const handleBack = () => {
//   setActiveStep((prevActiveStep) => prevActiveStep - 1);
// };

// {
/* <Modal open={false}>
<Box
  sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: colors.root[0],
    borderRadius: "15px",
    p: 4,
  }}
>
  <Box>
    <Box
      pb={3}
      component="img"
      src={testeBag}
      sx={{
        maxWidth: "180px",
        display: "flex",
        margin: "auto",
      }}
    />
    <Typography fontWeight="bold" textAlign="center" variant="h5">
      Atacado
    </Typography>
    <Typography textAlign="center" variant="body2">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas eu neque laoreet, dapibus diam nec, scelerisque
      massa.
    </Typography>
  </Box>
  <MobileStepper
    variant="dots"
    steps={3}
    position="static"
    activeStep={activeStep}
    sx={{
      maxWidth: 400,
      flexGrow: 1,
      backgroundColor: colors.root[0],
    }}
    nextButton={
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeStep === 2}
      >
        <KeyboardArrowRight />
      </Button>
    }
    backButton={
      <Button
        size="small"
        onClick={handleBack}
        disabled={activeStep === 0}
      >
        <KeyboardArrowLeft />
      </Button>
    }
  />
</Box>
</Modal> */
// }
