import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardActionArea,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { colors } from "mui-overrides/principles";

export default function OrderCard({ order }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { id, status, price, date, products } = order;

  const orderStatusIcon =
    order.status === "concluído" ? (
      <CheckCircleIcon color="success" />
    ) : (
      <CancelIcon color="error" />
    );

  const ariaLabelValue = `O preço é de R$ ${price}`;

  return (
    <CardActionArea>
      <Card
        variant="outlined"
        style={{ padding: isDesktop ? "15px" : "5px", borderRadius: "12px" }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <CardContent style={{ paddingBottom: "0px" }}>
              <Box display="flex">
                {orderStatusIcon}
                <Box>
                  <Typography ml={1} fontWeight="bold">
                    Pedido {status} • Identificação nº {id}
                  </Typography>
                  <Typography ml={1} variant="body2" mb={1}>
                    {date}
                  </Typography>
                </Box>
              </Box>

              <Box my={2}>
                <Divider />
              </Box>
              <Box mt={1}>
                <Typography mb={1}>
                  <List>
                    {products.slice(0, 3).map((order, index) => (
                      <ListItem disablePadding key={index}>
                        <ListItemIcon>{order.size}x</ListItemIcon>
                        <ListItemText primary={order.name} />
                      </ListItem>
                    ))}
                    <ListItem disablePadding>
                      <ListItemText
                        primary={`E mais ${products.length} items...`}
                        sx={{ color: colors.root[300] }}
                      />
                    </ListItem>
                  </List>
                </Typography>
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mt={2}
              >
                <Typography fontWeight="bold" onClick={() => {}}>
                  Total
                </Typography>
                <Typography
                  fontWeight="bold"
                  textAlign="center"
                  aria-label={ariaLabelValue}
                >
                  R$ {price}
                </Typography>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </CardActionArea>
  );
}
