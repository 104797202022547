const ordersList = [
  {
    id: "1",
    status: "concluído",
    date: "Qua, 21 dezembro 2022",
    products: [
      {
        name: "Banana Prata",
        size: 40,
      },
      {
        name: "Batata doce",
        size: 20,
      },
      {
        name: "Abacaxi",
        size: 12,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
    ],
    price: 500.0,
  },
  {
    id: "1",
    status: "concluído",
    date: "Qua, 21 dezembro 2022",
    products: [
      {
        name: "Banana Prata",
        size: 40,
      },
      {
        name: "Batata doce",
        size: 20,
      },
      {
        name: "Abacaxi",
        size: 12,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
    ],
    price: 2400.0,
  },
  {
    id: "1",
    status: "cancelado",
    date: "Qua, 21 dezembro 2022",
    products: [
      {
        name: "Banana Prata",
        size: 40,
      },
      {
        name: "Batata doce",
        size: 20,
      },
      {
        name: "Abacaxi",
        size: 12,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
    ],
    price: 720.0,
  },
  {
    id: "1",
    status: "concluído",
    date: "Qua, 21 dezembro 2022",
    products: [
      {
        name: "Banana Prata",
        size: 40,
      },
      {
        name: "Batata doce",
        size: 20,
      },
      {
        name: "Abacaxi",
        size: 12,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
    ],
    price: 350.0,
  },
  {
    id: "1",
    status: "concluído",
    date: "Qua, 21 dezembro 2022",
    products: [
      {
        name: "Banana Prata",
        size: 40,
      },
      {
        name: "Batata doce",
        size: 20,
      },
      {
        name: "Abacaxi",
        size: 12,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
    ],
    price: 500.0,
  },
  {
    id: "1",
    status: "concluído",
    date: "Qua, 21 dezembro 2022",
    products: [
      {
        name: "Banana Prata",
        size: 40,
      },
      {
        name: "Batata doce",
        size: 20,
      },
      {
        name: "Abacaxi",
        size: 12,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
      {
        name: "Banana Prata",
        size: 59,
      },
    ],
    price: 700.0,
  },
];

export default ordersList;
