import React, { useEffect, useState } from "react";
import {
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Card,
  CardContent,
  Box,
  CardActionArea,
} from "@mui/material";
import DesktopTopMenu from "view/components/headers/desktop-header";
import MobileBottomMenu from "view/components/headers/mobile-header";
import HomeLoading from "../../components/wholesale-loading";

export default function MyAccount() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  if (loading) {
    return <HomeLoading />;
  }

  return (
    <>
      {isDesktop ? <DesktopTopMenu /> : <MobileBottomMenu />}

      <Grid
        container
        py={3}
        px={{ xs: 1.5, lg: 10 }}
        spacing={isDesktop ? 2 : 1.5}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold">
            Meus dados
          </Typography>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <CardActionArea>
            <Card
              variant="outlined"
              style={{
                padding: isDesktop ? "15px" : "5px",
                borderRadius: "12px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box
                        width="50px"
                        height="50px"
                        borderRadius="50%"
                        bgcolor="gray"
                        mr={2}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">
                        Dados da empresa
                      </Typography>
                      <Typography variant="small">
                        Nome, CNPJ e endereço
                      </Typography>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </CardActionArea>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <CardActionArea>
            <Card
              variant="outlined"
              style={{
                padding: isDesktop ? "15px" : "5px",
                borderRadius: "12px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box
                        width="50px"
                        height="50px"
                        borderRadius="50%"
                        bgcolor="gray"
                        mr={2}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">
                        Dados da empresa
                      </Typography>
                      <Typography variant="small">
                        Nome, CNPJ e endereço
                      </Typography>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </CardActionArea>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <CardActionArea>
            <Card
              variant="outlined"
              style={{
                padding: isDesktop ? "15px" : "5px",
                borderRadius: "12px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box
                        width="50px"
                        height="50px"
                        borderRadius="50%"
                        bgcolor="gray"
                        mr={2}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">
                        Endereços de entrega
                      </Typography>
                      <Typography variant="small">
                        Altere ou adicione novos endereços de entrega
                      </Typography>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </CardActionArea>
        </Grid>
      </Grid>
    </>
  );
}
