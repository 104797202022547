export const blue = {
  50: "#F3FCFF",
  100: "#CCF1FF",
  300: "#82DDFF",
  500: "#00BAFF",
  700: "#079BD3",
  900: "#0780AE",
};

export const red = {
  50: "#FFF3F3",
  100: "#FFE0E0",
  300: "#FF9492",
  500: "#FF6562",
  700: "#D35755",
  900: "#B64341",
};

export const green = {
  50: "#27AE60",
  100: "#CCF6E9",
  300: "#78E2C1",
  500: "#00D494",
  700: "#07B07D",
  900: "#078A63",
};

export const yellow = {
  50: "#FFFCEB",
  100: "#FFF5A5",
  300: "#FFE388",
  500: "#FFD757",
  700: "#FFA91F",
  900: "#E8940E",
};

export const smoke = {
  10: "#FCFDFD",
  25: "#F8FAFC",
  50: "#F5F8FA",
  100: "#EAEEF4",
  200: "#D8DDEB",
  300: "#BEC7D6",
  400: "#AEBAC9",
  500: "#9FABBB",
  600: "#8A94A3",
  700: "#757D8C",
  800: "#484F5F",
  900: "#2E3545",
  A100: "#E8ECF6",
  A200: "#D2DAF1",
  A400: "#97A9C2",
  A700: "#63718C",
};

export const storm = {
  50: "#F2F8F9",
  100: "#C5D3E0",
  300: "#A2B2C5",
  500: "#8B9FB7",
  700: "#6C8AA2",
  900: "#577188",
};

export const orange = {
  50: "#F48020",
};

export const root = {
  0: "#FFFFFF",
  100: "#C8C8C8",
  300: "#A4A4A4",
  500: "#7D7D7D",
  700: "#5F5F5F",
  900: "#222222",
};

const colors = {
  blue,
  red,
  green,
  yellow,
  smoke,
  storm,
  orange,
  root,
};

export default colors;
